import { css } from '@emotion/react';
import { memo } from 'react';
import { fill_vertical_cross_center } from '~/modules/AppLayout/FlexGridCss';
//todo:
//近日振幅
//近月(sm)振幅
//symbol=TXAM-1&resolution=D 20天
//月合約: 開 高(發生日) 低(發生日) 月振幅(current) 月成本(current)
//週合約: 開 高(發生日) 低(發生日) 月振幅(current) 月成本(current)
//處理 合約代號邏輯(opkevin)
//關卡價格(多)
//關卡價格(空)
//須知道 當下合約的高低開 成本
//100%
//75%
//50%
//25%
export const Freeman_SidePane2 = memo(function Freeman_SidePane2() {
    return <div css={classes.container}>123</div>;
});
const classes = {
    container: css `
    ${fill_vertical_cross_center};
    padding: 4px;
    gap: 4px;
    background-color: #151515;
    border-radius: 4px;
    user-select: none;
  `,
};

import { css } from '@emotion/react';
import { memo } from 'react';
import IntradayTrendChart from '~/modules/trendChart';
import { store } from '~/pages/heineken_template/_private/store';
import FuiButton from '~/pages/heineken_template/components/FuiButton';
import TitleSymbolQuote from '~/modules/investment-consultant/symbolList/body/SymbolQuoteTitle';
import { lung88988Stock_strategiesGroup } from './lung88988Stock_initStrategies';
import { Styleds } from './styleds';
import { useSnapshot } from 'valtio';
import { useChartPositionStore } from '~/modules/cons-misc/mode-buttons/ChartPositionButton';
import { lung88988Stock_agentStore } from './lung88988Stock_agentStore';
import { SymbolChangeRanking } from '~/modules/investment-consultant/symbolList/list/SymbolChangeRankingList';
import { usePopularPick } from '~/modules/investment-consultant/stockPickTable/stockPickTable';
import { useTurnoverAndZScoreScreener } from '../modules/useTurnoverAndZScoreScreener';
import { useStockPriceChangeDistributionState } from '~/pages/futuresai/stock-price-change-distribution/components/useStockPriceChangeDistributionState';
import { RealtimeSymbolPage } from './RealtimeSymbolPage';
import { TransactionSymbolPage } from './TransactionSymbolPage';
import RiseFallBarGrade from '~/pages/futuresai/stock-price-change-distribution/components/RiseFallBarGrade';
export const Lung88988Stock_Sidepane1 = memo(function Lung88988Stock_Sidepane1() {
    const charting = useSnapshot(store.charting);
    const chartPositionState = useSnapshot(useChartPositionStore);
    //screener
    const popularData = usePopularPick({ date: '', sort: 'volume', limit: 22 });
    //熱門股
    const popularSymbol = popularData.resultSymbol?.filter(item => item.length < 5);
    //主力當沖
    const dayTradeSymbol = useTurnoverAndZScoreScreener();
    const StrategyGroup = memo(function StrategyGroup() {
        return (<Styleds.StrategyButtonGroup>
        {lung88988Stock_strategiesGroup.main.map(config => {
                const isActive = charting.strategySelected.displayName === config.displayName;
                return (<FuiButton.Display key={config.displayName} active={isActive} onClick={event => {
                        store.charting.setStrategy(config);
                        store.charting.updateFromStrategyState();
                    }}>
              {config.displayName}
            </FuiButton.Display>);
            })}
      </Styleds.StrategyButtonGroup>);
    });
    const PositionGroup = memo(function PositionButtonGroup() {
        return (<Styleds.PositionButtonGroup>
        <FuiButton.Display variant='long' active={chartPositionState.positionType === 'long'} onClick={event => {
                useChartPositionStore.positionType = 'long';
                store.charting.updateFromState();
            }}>
          多方操作
        </FuiButton.Display>
        <FuiButton.Display active={chartPositionState.positionType === 'default'} onClick={event => {
                useChartPositionStore.positionType = 'default';
                store.charting.updateFromState();
            }}>
          預設
        </FuiButton.Display>
        <FuiButton.Display variant='short' active={chartPositionState.positionType === 'short'} onClick={event => {
                useChartPositionStore.positionType = 'short';
                store.charting.updateFromState();
            }}>
          空方操作
        </FuiButton.Display>
      </Styleds.PositionButtonGroup>);
    });
    const ScreenerTypeGroup = memo(function ScreenerTypeGroup() {
        return (<Styleds.ScreenerTypeButtonGroup>
        <lung88988Stock_agentStore.tabs1.WithButton to='熱門股'>
          <FuiButton.Display>熱門股</FuiButton.Display>
        </lung88988Stock_agentStore.tabs1.WithButton>

        <lung88988Stock_agentStore.tabs1.WithButton to='主力當沖'>
          <FuiButton.Display>主力當沖</FuiButton.Display>
        </lung88988Stock_agentStore.tabs1.WithButton>

        <lung88988Stock_agentStore.tabs1.WithButton to='持倉中'>
          <FuiButton.Display>即時訊號</FuiButton.Display>
        </lung88988Stock_agentStore.tabs1.WithButton>

        <lung88988Stock_agentStore.tabs1.WithButton to='當日交易'>
          <FuiButton.Display>當日紀錄</FuiButton.Display>
        </lung88988Stock_agentStore.tabs1.WithButton>
      </Styleds.ScreenerTypeButtonGroup>);
    });
    return (<Styleds.SideBar>
      <TitleSymbolQuote.EstimatedVol symbol='TSEA' unit='億'/>
      <Styleds.TrendChartContainer>
        <IntradayTrendChart symbol={'TSEA'} ticksSize={14} ticksHeight={20} priceTicksMargin={2.5} priceTicksSize={14}/>
      </Styleds.TrendChartContainer>
      <StrategyGroup />
      <PositionGroup />
      <Styleds.SidebarTitle>熱門股趨勢追蹤</Styleds.SidebarTitle>
      <ScreenerTypeGroup />
      <Styleds.SymbolListContainer>
        <lung88988Stock_agentStore.tabs1.WithContent for='熱門股'>
          <SymbolChangeRanking useChart={store.charting} symbolNumber={popularSymbol ?? []} checkTrendMode='NoCheck' countArray={[1, 5, 20, 60]}/>
        </lung88988Stock_agentStore.tabs1.WithContent>

        <lung88988Stock_agentStore.tabs1.WithContent for='主力當沖'>
          <SymbolChangeRanking useChart={store.charting} symbolNumber={dayTradeSymbol ?? []} checkTrendMode='NoCheck' countArray={[1, 5, 20, 60]}/>
        </lung88988Stock_agentStore.tabs1.WithContent>

        <lung88988Stock_agentStore.tabs1.WithContent for='當日交易'>
          <TransactionSymbolPage />
        </lung88988Stock_agentStore.tabs1.WithContent>

        <lung88988Stock_agentStore.tabs1.WithContent for='持倉中'>
          <RealtimeSymbolPage />
        </lung88988Stock_agentStore.tabs1.WithContent>
      </Styleds.SymbolListContainer>
      <Styleds.DataChartContainer>
        <useStockPriceChangeDistributionState.Provider>
          <DataChart />
        </useStockPriceChangeDistributionState.Provider>
      </Styleds.DataChartContainer>
    </Styleds.SideBar>);
});
//市場漲跌家數
export const DataChart = memo(function DataChart_() {
    const { state: { overallCount, fallCount, flatCount, riseCount, vBar }, } = useStockPriceChangeDistributionState.useContainer();
    return (<div css={css `
        height: 100%;
        width: 100%;
        font-size: 14px;
      `}>
      <RiseFallBarGrade data={vBar}/>
    </div>);
});
